<div
  *ngIf="headerPage$ | async as headerPage"
  [class.header--portal]="isHero"
  [class.header--sub]="isSubNavigation$ | async"
  class="header flex items-center gap-20 w-full h-48 md:h-auto md:py-16 md:pl-8 md:pr-16"
  role="banner"
>
  <div *ngIf="!isBackHeader; else back" class="wrapper flex items-center gap-20 w-full h-full">
    <a
      class="header__support rounded-10 min-w-32 h-32 md:min-w-48 md:h-48 flex items-center justify-center bg-background-black"
      [routerLink]="navigation.Home"
      [attr.aria-label]="headerPage?.translation?.data?.moveToMainAriaLabel"
    >
      <mat-icon [svgIcon]="svgIcons.SUPPORT" [attr.alt]="headerPage?.translation?.data?.supportIconAlt"></mat-icon>
    </a>

    <div class="header__navigations" role="navigation" [attr.aria-label]="headerPage?.translation?.data?.mainNavigationAriaLabel">
      <app-navigation
        [items]="(navigationItems$ | async) ?? []"
        [isPortal]="isPortal"
        (isSubNavigationEvent)="subNavigationEvent($event)"
      />
    </div>

    <ng-container *ngIf="(isAuth$ | async) === false; else profile">
      <div class="header__menu header__menu--login">
        <app-btn
          [title]="headerPage?.translation?.data?.login"
          [hasGradient]="false"
          (clickEvent)="goToLogin()"
          class="header__login"
          [attr.aria-label]="headerPage?.translation?.data?.login"
        ></app-btn>
      </div>
    </ng-container>

    <ng-template #profile>
      <div
        [matMenuTriggerFor]="menu"
        class="header__menu header__menu flex items-center gap-4 ml-auto"
        tabindex="0"
        role="menu"
        [attr.aria-label]="headerPage?.translation?.data?.profile"
        (keydown.enter)="$event.preventDefault(); pressMenu($event)"
      >
        <h6>{{ name }}</h6>
        <mat-icon [svgIcon]="svgIcons.POLYGON" [attr.alt]="headerPage?.translation?.data?.profile"></mat-icon>
      </div>

      <mat-menu #menu="matMenu" class="profile-menu" xPosition="after" [attr.aria-label]="headerPage?.translation?.data?.account">
        <a
          mat-menu-item
          (click)="menuEvent('profile')"
          (keydown.enter)="menuEvent('profile')"
          class="menu__item"
          [attr.aria-label]="headerPage?.translation?.data?.profile"
        >
          {{ headerPage?.translation?.data?.profile }}
        </a>
        <a
          mat-menu-item
          (click)="menuEvent('account')"
          (keydown.enter)="menuEvent('account')"
          class="menu__item"
          [attr.aria-label]="headerPage?.translation?.data?.account"
        >
          {{ headerPage?.translation?.data?.account }}
        </a>
        <button
          mat-menu-item
          (click)="menuEvent('logout')"
          (keydown.enter)="menuEvent('logout')"
          class="menu__item"
          [attr.aria-label]="headerPage?.translation?.data?.logout"
        >
          {{ headerPage?.translation?.data?.logout }}
        </button>
      </mat-menu>
    </ng-template>

    <div *ngIf="accessibilityPage$ | async as accessibilityPage" class="relative hidden xl:block">
      <button
        class="btn icon-transparent default flex items-center"
        [attr.aria-label]="accessibilityPage?.translation?.data?.buttonAriaLabel"
        (click)="accessibilityService.toggle()"
      >
        <mat-icon [svgIcon]="svgIcons.CONTRAST" [attr.alt]="accessibilityPage?.translation?.data?.iconAlt"></mat-icon>
      </button>

      <app-header-a11y
        *ngIf="accessibilityService.isOpen"
        @fade
        class="header__a11y"
        appClickOutside
        (appClickOutside)="hideAccess()"
        [shoulSkipFirst]="false"
      ></app-header-a11y>
    </div>

    <div class="relative hidden xl:flex items-center cursor-pointer gap-2" (click)="switchLang()">
      <mat-icon [svgIcon]="svgIcons.PLANET" alt="multilang"></mat-icon>
      <span class="text-small">
        {{ langService.lang() === 'ua' ? 'EN' : 'UA' }}
      </span>
    </div>
  </div>

  <ng-template #back>
    <div class="wrapper flex items-center gap-20 w-full h-full justify-between">
      <div
        class="flex gap-4 items-center cursor-pointer"
        (click)="goBack()"
        (keydown.enter)="goBack()"
        (keydown.space)="goBack()"
        (keydown.backspace)="goBack()"
        tabindex="0"
        [attr.aria-label]="headerPage?.translation?.data?.goBackLabel"
      >
        <mat-icon [svgIcon]="svgIcons.ARROW_BACK" [attr.alt]="headerPage?.translation?.data?.goBackLabel"></mat-icon>
        <h6 class="hidden md:block">{{ headerPage?.translation?.data?.goBackLabel }}</h6>
      </div>
      <div class="header__support">
        <mat-icon [svgIcon]="svgIcons.SUPPORT" [attr.alt]="headerPage?.translation?.data?.supportIconAlt"></mat-icon>
      </div>
    </div>
  </ng-template>
</div>
