<div *ngIf="page$ | async as page" class="block" appClickOutside (clickOutside)="onCloseA11y()">
  <div class="block__inner">
    <h5 [innerHTML]="page.translation.data.title"></h5>
    <div class="block__control-group flex flex-col">
      <div class="block__control">
        <p class="h8 mb-1" [innerHTML]="page.translation.data.textSize"></p>
        <div class="d-flex">
          <button
            [attr.aria-label]="page.translation.data.textSizeDecrement"
            [disabled]="accessibilityService.fontSizeLevel === accessibilityService.minFontSizeLevel"
            (click)="accessibilityService.decrementFontSizeLevel()"
          >
            <div class="icon size-minus"></div>
          </button>
          <button
            [attr.aria-label]="page.translation.data.textSizeIncrement"
            [disabled]="accessibilityService.fontSizeLevel === accessibilityService.maxFontSizeLevel"
            (click)="accessibilityService.incrementFontSizeLevel()"
          >
            <div class="icon size-plus"></div>
          </button>
        </div>
      </div>
      <div class="block__control">
        <p class="h8 mb-1" [innerHTML]="page.translation.data.letterSpacing"></p>
        <div class="d-flex">
          <button
            [attr.aria-label]="page.translation.data.letterSpacingDecrement"
            [disabled]="accessibilityService.letterSpacingLevel === accessibilityService.minLetterSpacingLevel"
            (click)="accessibilityService.decrementLetterSpacingLevel()"
          >
            <div class="icon size-minus"></div>
          </button>
          <button
            [attr.aria-label]="page.translation.data.letterSpacingIncrement"
            [disabled]="accessibilityService.letterSpacingLevel === accessibilityService.maxLetterSpacingLevel"
            (click)="accessibilityService.incrementLetterSpacingLevel()"
          >
            <div class="icon size-plus"></div>
          </button>
        </div>
      </div>
      <div class="block__control">
        <p class="h8 mb-1" [innerHTML]="page.translation.data.color">Колір</p>
        <div class="d-flex">
          <button
            [attr.aria-label]="page.translation.data.defaultColorMode"
            [attr.aria-pressed]="accessibilityService.contrastMode === ContrastMode.Color"
            (click)="accessibilityService.setContrast(ContrastMode.Color)"
          >
            <div class="icon mode-color"></div>
            <span [innerHTML]="page.translation.data.contrast">Контраст</span>
          </button>
          <button
            [attr.aria-label]="page.translation.data.contrastColorMode"
            [attr.aria-pressed]="accessibilityService.contrastMode === ContrastMode.Contrast"
            (click)="accessibilityService.setContrast(ContrastMode.Contrast)"
          >
            <div class="icon mode-contrast"></div>
            <span [innerHTML]="page.translation.data.darkWhite">ЧБ</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="block__bottom">
    <button
      class="btn block__bottom-reset"
      [disabled]="
        accessibilityService.contrastMode === ContrastMode.Color &&
        accessibilityService.fontSizeLevel === accessibilityService.minFontSizeLevel &&
        accessibilityService.letterSpacingLevel === accessibilityService.minLetterSpacingLevel
      "
      [innerHTML]="page.translation.data.reset"
      (click)="onReset()"
    >
      Скинути
    </button>
  </div>
</div>
