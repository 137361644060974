import { PageService } from '@app/core/services/page.service'
import { map } from 'rxjs'

import { AsyncPipe, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core'

import { ContrastMode } from 'src/app/core/interfaces/accessibility'
import { AccessibilityService } from 'src/app/core/services/accessibility.service'

@Component({
  selector: 'app-header-a11y',
  templateUrl: './header-a11y.component.html',
  styleUrls: ['./header-a11y.component.scss'],
  standalone: true,
  imports: [NgIf, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderA11yComponent implements OnInit {
  ContrastMode = ContrastMode

  page$ = this.pageService.commonTranslations$.pipe(map((translations) => translations['accessibility']))

  constructor(
    readonly accessibilityService: AccessibilityService,
    private pageService: PageService,
  ) {}

  @HostListener('document:keydown.escape', ['$event'])
  onEscKeydown(): void {
    this.onCloseA11y()
  }

  ngOnInit(): void {
    this.accessibilityService.enable()
  }

  onCloseA11y(): void {
    if (this.accessibilityService.isOpen) {
      this.accessibilityService.close()
    }
  }

  onReset(): void {
    this.accessibilityService.reset()
    this.accessibilityService.close()
  }
}
